<template>
  <div>
  <b-form>
    <div class="d-flex justify-content-between">
      <h3 class="mb-0 mr-1">Editando investidor</h3>
      <button @click="saveInvestor" type="button" class="btn btn-primary">Salvar</button>
    </div>

    <b-form-group label-class="font-weight-bold" label-cols="12" class="mb-0">
      <b-row>
        <b-col cols="4">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input disabled id="nome" v-model="investor.nome" name="nome" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Celular" label-for="telefone">
            <b-form-input
              disabled
              id="telefone"
              v-model="investor.telefone"
              v-mask="['(##) #####-####']"
              name="telefone"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Email" label-for="apto_para_operar">
            <b-form-input disabled id="email" v-model="investor.email" name="email" />
          </b-form-group>
        </b-col>
        <b-col cols="4" v-if="investor.type == 'Cedente::User'">
          <b-form-group label="Acesso ao portal?" label-for="acesso_portal">
            <b-form-checkbox v-model="investor.acesso_cedente_portal_investidor" switch inline/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="">
            <div class="p-1 bg-gray border-gray">
              <span> Inclua abaixo os códigos IF que este convidado terá acesso. </span>
              <div class="d-flex mt-1 align-items-start">
                <b-form-input v-model="codigo_if" placeholder="Digite o código IF" />
                <b-button
                  variant="primary"
                  :disabled="codigo_if.length < 2"
                  class="text-nowrap ml-2 d-flex align-items-center"
                  @click="addCodigoIF"
                >
                  Incluir código IF
                  <feather-icon icon="PlusIcon" class="ml-50" size="18" />
                </b-button>
              </div>
            </div>
            <div class="cnpjListBody">
              <vue-scrollbar ref="Scrollbar" :style="scrollbar">
                <table class="table-container">
                  <tr v-for="(code, index) in investor.ifb3_codes" :key="index">
                    <td class="w-100">{{ code }}</td>
                    <td>
                      <b-button
                        variant="flat-warning"
                        size="sm"
                        @click="removeCode(code)"
                        class="text-nowrap"
                      >
                        Excluir <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </td>
                  </tr>
                </table>
              </vue-scrollbar>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form-group>
  </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BButton, BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'

export default {
  name: 'InviteForm',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    VueScrollbar,
  },
  props: {
    investor: {
      type: Object,
      default: () => ({
        nome: '',
        email: '',
        telefone: '',
        acesso_cedente_portal_investidor: false
      }),
    },
  },
  directives: { mask },
  data() {
    return {
      codigo_if: '',
      if_codes: [],
      scrollbar: {
        maxHeight: '250px',
        width: '100%',
      },
    }
  },
  methods: {
    async saveInvestor() {
      this.$swal.fire({
        title: 'Processando',
        text: 'Aguarde enquanto os dados são atualizados!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: () => {
          this.$swal.showLoading()
        },
      })

      try {
        const invite = {
          ...this.invite,
          ifb3_autorizados: this.if_codes,
        }
        await this.$store.dispatch('admin/updateUser', this.investor)
        this.$swal
          .fire({
            title: 'Sucesso',
            text: 'Usuario atualizado com sucesso',
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          })
          .then((result) => {
            this.$emit('closeForm')
          })
      } catch (error) {
        console.log(error)
      }
    },
    async addCodigoIF() {
      const payload = {
        id: this.investor.id,
        codigo_ifb3: this.codigo_if,
      }

      try {
        await this.$store.dispatch('admin/addIfB3', payload)
        this.$swal.fire({
          title: 'Sucesso',
          text: 'Código IF adicionado com sucesso',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        })
        this.codigo_if = ''
        this.$emit('updateCodes')
      } catch (error) {
        console.log(error)
      }
    },
    async removeCode(code) {
      const payload = {
        id: this.investor.id,
        codigo_ifb3: code,
      }

      try {
        await this.$store.dispatch('admin/removeIfB3', payload)
        this.$swal.fire({
          title: 'Sucesso',
          text: 'Código IF removido com sucesso',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        })
        this.$emit('updateCodes')
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
.border-gray {
  border: 1px solid #dae1e8;
}

.bg-gray {
  background-color: #eff4f6;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-container td {
  border: 1px solid #dae1e8;
  border-top: none;
  padding: 8px 12px;
  text-align: left;
}

.table-container td.cnpj {
  width: 15%;
  white-space: nowrap;
}

.table-container td.button {
  width: 12%;
  white-space: nowrap;
}
</style>
