<template>
  <b-card-actions class="invite-card-actions card-cs-border mb-0" collapsed action-collapse>
    <template #title>
      <div class="d-flex align-items-center">
        {{ invite.nome }}
        <b-badge
          :variant="`light-${inviteStatusVariant.variant}`"
          :class="`border-${inviteStatusVariant.variant} ml-2`"
          pill
        >
          {{ inviteStatusVariant.label }}
        </b-badge>
      </div>
    </template>
    <div>
      <div class="d-flex">
        <div class="d-flex flex-column mr-2">
          <span class="fz-12"> Último envio </span>
          <span class="text-dark fz-12"> {{ invite.ultimo_envio | formatDate }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span class="fz-12"> Telefone </span>
          <span class="text-dark fz-12"> {{ invite.telefone }} </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span class="fz-12"> CPF </span>
          <span class="text-dark fz-12"> - </span>
        </div>
        <div class="d-flex flex-column mr-2">
          <span class="fz-12"> Email </span>
          <span class="text-dark fz-12"> {{ invite.email }} </span>
        </div>
      </div>
      <div class="mt-1 d-flex justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-2">
            <span class="fz-12"> Códigos IF </span>
            <span class="text-dark fz-12">
              {{ invite.ifb3_autorizados ? invite.ifb3_autorizados.join(' ') : '-' }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-end">
          <b-badge
            v-if="!invite.aceito"
            class="border-warning card-cs-bg mr-1 cursor-pointer"
            :id="`copyTooltip-${invite.id}`"
            @click="copyLink()"
          >
            <feather-icon icon="CopyIcon" class="mr-50 text-warning" />
            <span class="text-warning"> Link de cadastro </span>
            <b-tooltip
              ref="tooltip"
              triggers="click"
              :target="`copyTooltip-${invite.id}`"
              title="Copiado!"
              variant="primary"
              :delay="{ show: 100, hide: 500 }"
            />
          </b-badge>

          <b-badge
            class="border-warning card-cs-bg cursor-pointer mr-1"
            @click="deleteInvite"
            v-if="!invite.aceito"
          >
            <feather-icon icon="Trash2Icon" class="mr-50 text-warning" />
            <span class="text-warning"> Excluir convite </span>
          </b-badge>

          <b-button
            variant="outline-primary"
            class="bg-white text-dark"
            @click="sendInvite"
            size="sm"
            v-if="!invite.aceito"
          >
            <span class="font-weight-bolder">
              {{ invite.ultimo_envio ? 'Reenviar e-mail' : 'Enviar e-mail' }}
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </b-card-actions>
</template>

<script>
import { BCard, BBadge, BButton, BTooltip, VBTooltip } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { BCardActions } from '@/@core/components/b-card-actions'

export default {
  name: 'InviteCard',
  components: {
    BCard,
    BBadge,
    BButton,
    BTooltip,
    BCardActions,
  },
  filters: {
    formatDate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    invite: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteInvite() {
      this.$swal
        .fire({
          title: 'Tem certeza?',
          text: 'Você não poderá reverter essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, excluir!',
          cancelButtonText: 'Não, cancelar!',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.$store.dispatch('admin/deleteInvestorInvite', this.invite.id)
              this.$swal.fire({
                title: 'Convite excluído com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$emit('inviteDeleted')
            } catch (error) {}
          }
        })
    },
    async sendInvite() {
      this.$swal
        .fire({
          title: 'Tem certeza?',
          text: `Você está ${
            this.invite.ultimo_envio ? 'reenviando' : 'enviando'
          } o convite para o usuário!`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, enviar!',
          cancelButtonText: 'Não, cancelar!',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.$store.dispatch('admin/sendInvestorInvite', this.invite.id)
              this.$swal.fire({
                title: 'Convite enviado com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$emit('inviteResent')
            } catch (error) {}
          }
        })
    },
    statusBadge(status) {
      const statuses = {
        active: {
          variant: 'primary',
          text: 'Ativo',
        },
        inactive: {
          variant: 'warning',
          text: 'Inativo',
        },
        tested: {
          variant: 'info',
          text: 'Testado',
        },
      }

      return statuses[status]
    },
    copyLink() {
      this.$copyText(this.invite.url_convite).then((e) => {
        setTimeout(() => this.$refs.tooltip.$emit('close'), 750)
      })

      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 750)
    },
  },
  computed: {
    inviteStatusVariant() {
      let variant = {
        variant: 'primary',
        label: 'Ativo',
      }

      if (this.invite.aceito) {
        variant = {
          variant: 'success',
          label: 'Cadastro realizado',
        }
      } else {
        variant = {
          variant: 'warning',
          label: 'Aguardando cadastro',
        }
      }
      return variant
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';

.card-cs-border {
  border-radius: 8px;
  border: 1px solid #becad4 !important;
  box-shadow: none !important;
}

.card-cs-bg {
  border-radius: 8px;
  background: #f8fafc;
}

.invite-card-actions {
  .card-header {
    padding: 0.75rem 1.25rem;
    background-color: #f8fafc;
  }

  .card-body {
    padding: 0.75rem 1.25rem;
    background-color: #f8fafc;
  }
}

.fz-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
</style>
