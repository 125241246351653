<template>
  <b-card>
    <investor-form :investor="investor" @updateCodes="getIfB3Codes" />
  </b-card>
</template>

<script>
import { BCard, BButton, BInputGroup, BInputGroupAppend, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import InvestorForm from '@/views/admin/investor/components/InvestorForm.vue'
import InviteCard from '@/views/admin/investor/components/InviteCard.vue'

export default {
  name: 'EditUserPage',
  components: {
    vSelect,
    BCard,
    BButton,
    Datatable,
    BFormInput,
    InvestorForm,
    InviteCard,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      investor: {},
    }
  },
  async mounted() {
    await this.getInvestor()
    await this.getIfB3Codes()
  },
  methods: {
    async getInvestor() {
      const { data } = await this.$store.dispatch('admin/getUser', this.$route.params.id)
      this.investor = data.data
    },
    async getIfB3Codes() {
      const { data } = await this.$store.dispatch('admin/getUserIfB3Codes', this.$route.params.id)
      this.$set(this.investor, 'ifb3_codes', data.data)
    },
  },
}
</script>

<style lang="scss"></style>
